export const socialsData = {
    github: 'https://github.com/teebabs521',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/teebabs52/',
    instagram: '',
    codepen: 'https://codepen.io/Teebabs004',
    twitter: 'https://twitter.com/TeeJayIbrahim',
    reddit: '',
    blogger: '',
    medium: 'https://medium.com/@',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}