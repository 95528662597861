export const educationData = [
    {
        id: 1,
        institution: 'The Polytechnic, Ibadan',
        course: 'Civil Engineering',
        startYear: '2009',
        endYear: '2011'
    },
    {
        id: 2,
        institution: 'University of Ibadan',
        course: 'Food Technology',
        startYear: '2012',
        endYear: '2016'
    },
]